<template>
  <button :class="mode">
    <slot />
  </button>
</template>

<script>
export default {
  props: ['mode']
}
</script>

<style scoped>
button {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #ee8e67;
  border: 1px solid #ee8e67;
  color: white;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #f0a17d;
  border-color: #ee8e67;
}

.selected {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #f0a17d;
  border: 1px solid #f0a17d;
  color: #ee8e67;
  cursor: pointer;
}

.selected:hover,
.selected:active {
  background-color: #f0a17d;
  border-color: #ee8e67;
}

.flat {
  background-color: transparent;
  color: #ee8e67;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #f0a17d;
}
</style>